<!--
########################
# My Swiss Lunch V2    #
#  2023                #
#  PHG - Alpinit       #
########################
-->

<template>
    <div>

        <div class="columns">
            <div class="column is-hidden-mobile">
                <br /><br />
                <img src="../assets/cuisinier.svg" style="width:200%;" class="responsive">
            </div>

            <div class="column is-one-third has-background-primary">
                <h4 class="is-hidden-mobile"> Vos plats du jour disponibles {{ currentDateTxt }} à proximité de : <br />
                    <h5>{{ positionCourante }} </h5><br /> {{ PasdePDJTxt }}
                </h4>
                <h6 class="is-hidden-desktop is-hidden-tablet"> <br />Vos plats du jour disponibles {{ currentDateTxt }} à
                    proximité de : <br />
                    {{ positionCourante }} <br /> {{ PasdePDJTxt }}
                </h6>
                <br /><br />




                <!-- VERSION 3 -->
                <div v-for="(localpdj, indexpdj) in platsdujourordered.slice(indexdefilbas, indexdefilhaut) "
                    :key="indexpdj" class="container is-fluid ">
                    <div class="card" @click='toggleInfoWindow(localpdj, indexpdj)'>

                        <header v-if="localpdj" class="card-header">
                            <p class="card-header-title">
                                {{ localpdj.pdjnom }} - {{ localpdj.pdjprix }} CHF
                            </p>


                        </header>
                        <div class="card-content">
                            <div class="content">
                                <p class="subtitle is-6">{{ localpdj.restonom }} - {{
                                    localpdj.distancetxt
                                }}<br />{{ localpdj.restoadresse }} </p>

                            </div>
                        </div>


                        <footer class="card-footer">
                            <a :href="`https://www.google.com/maps/dir/${positionCourante.replace(/\s/g, '+')}/${localpdj.restonom.replace(/\s/g, '+')} ${localpdj.restoadresse.replace(/\s/g, '+')}`"
                                target="_blank" class="card-footer-item"> 
                                
                                <b-icon icon="walk" size="is-medium"
                                    class="is-danger">
                                </b-icon></a>


                            <a :href="`${localpdj.site}`" target="_blank" class="card-footer-item"> <b-icon icon="earth"
                                    size="is-medium" class="is-danger">
                                </b-icon>  </a><br />


                               
                          



        <a :href="`tel:${localpdj.tel}`" class="card-footer-item">
                                    
                                    <b-icon icon="phone" size="is-medium"
                                    class="is-danger"/>

                            </a>



                            
                          
                        </footer>


                    </div><br />


                </div>
                <div v-if="PasdePDJTxt == ''">    <div v-if="this.platsdujourordered.length > this.indexoffset">
                    <b-button @click="updateIndexDefil(false)">Précédent</b-button> &nbsp;&nbsp;&nbsp;&nbsp;
                    <b-button @click="updateIndexDefil(true)">Suivant</b-button>
                </div></div>
            </div>




            <!-- VERSION 3 END-->



            <div class="column is-half ">
                <gmap-map class="is-hidden-mobile  map" :zoom="14" :center="center" :clickableIcons=false>
                    <gmap-marker :key="index" v-for="(m, index) in platsdujourordered" :position="m.position"
                        @click="updatePDJIndex(index)"></gmap-marker>
                    <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                        @closeclick="infoWinOpen = false">
                        <div v-html="infoContent"></div>
                    </gmap-info-window>
                </gmap-map>

                <gmap-map class="column is-half is-hidden-desktop is-hidden-tablet mapmobile" :zoom="14" :center="center"
                    :clickableIcons=false>
                    <gmap-marker :key="index" v-for="(m, index) in platsdujourordered" :position="m.position"
                        @click="updatePDJIndex(index)"></gmap-marker>
                    <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                        @closeclick="infoWinOpen = false">
                        <div v-html="infoContent"></div>
                    </gmap-info-window>
                </gmap-map>

            </div>
        </div>

    </div>
</template>
   
<script>
var _ = require('lodash');
export default {
    name: "SearchResult",
    computed: {
        platsdujourordered: function () { //on génère un tableau organisé par la distance au point de recherche
            return _.orderBy(this.platsdujour, 'distance')
        }
    },
    watch: {
        indexpdj() { //si on modifie l'index du plat du jour que l'on regarde, on modifie le plat du jour courrant, et on ouvre l'info window correspondante
            this.pdjcourant = this.platsdujourordered[this.indexpdj];
            this.toggleInfoWindow(this.pdjcourant, this.indexpdj)

            
        },
        center() { //si on modifie le center, on prévient le composant principal de ce changement
            this.$emit('centerupdated', this.center);
        }
    },
    data() {
        return {
            pdjcourant: null, //le plat du jour courant, que l'on affiche en card et en infowindow
            indexpdj: 0, //l'index du pdj courant
            indexdefilbas: 0, // borne min bas pour le slice de pdjordered pour l'affichage
            indexdefilhaut: 4,//borne max haut pour le slice de pdjordered pour l'affichage
            indexoffset: 4,
            infoContent: '', //contenu de l'infowindow
            infoWindowPos: { // l'emplacement de l'infowindow
                lat: 0,
                lng: 0
            },
            infoWinOpen: false, //infowindow ouverte ou non 
            currentMidx: null, //ouvert en ce moment
            //optional: offset infowindow so it visually sits nicely on top of our marker
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            }
        };
    },
    props: { //on récupère le centre, la positioncourante (adresse texte) et le tableau des plats du jour depuis le composant mère
        platsdujour: Array,
        center: Object,
        positionCourante: String,
        currentDateTxt: String,
        PasdePDJTxt: String,
    },
    mounted() {
        //le plat du jour courant devient le premier dans la liste ordonnée
        //On ouvre l'info window correspondante
        this.pdjcourant = this.platsdujourordered[this.indexpdj];
        this.toggleInfoWindow(this.pdjcourant, this.indexpdj);
    },

    methods: {
        htmlEntities(str) {
            return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
        },
        updatePDJIndex(index) {
            this.indexpdj = index ;
            
        },
        //met à jour l'index entre 0 et le max (nb de pdj -1). Add = true : on ajoute. sinon on soustrait. 
        updateIndexPDJ(add) {
            if (add) // on ajoute
            {
                if (this.indexpdj >= 0 && this.indexpdj < this.platsdujourordered.length - 1)
                    this.indexpdj++;
            } else {
                if (this.indexpdj > 0)
                    this.indexpdj--;
            }
        },
        //met à jour l'index entre 0 et le max (nb de pdj -1). Add = true : on ajoute. sinon on soustrait. 
        updateIndexDefil(add) {

            if (add) // on ajoute
            {
                if (this.indexdefilbas <= this.platsdujourordered.length - this.indexoffset) {
                    this.indexdefilbas += this.indexoffset;
                    this.indexdefilhaut += this.indexoffset;
                }
            } else {
                if (this.indexdefilbas >= this.indexoffset) {
                    this.indexdefilbas -= this.indexoffset;
                    this.indexdefilhaut -= this.indexoffset;
                }
            }
        },
        //Afficher les infos window
        toggleInfoWindow: function (marker, idx) {
            if (marker) {
                this.infoWindowPos = marker.position;

                this.infoContent = this.getInfoWindowContent(marker);

                //check if its the same marker that was selected if yes toggle
                if (this.currentMidx == idx) {
                    this.infoWinOpen = !this.infoWinOpen;
                }
                //if different marker set infowindow to open and reset current marker index
                else {
                    this.infoWinOpen = true;
                    this.currentMidx = idx;
                }
            }
        },
        //génère le contenu de l'infowindow 
        getInfoWindowContent: function (marker) {
            if (marker) {
                return (`
       <b>${this.htmlEntities(marker.pdjnom)} </b><br/>
      ${marker.restonom}<br/>
      ${marker.restoadresse}<br/>
      ${this.htmlEntities(marker.pdjprix)} CHF <br/><br/>
      ${marker.tel}<br/>
      ${marker.site}
  `);
            }
        },

    }
};
</script>

<style scoped>
.input {
    font-size: 16px;
    font-size: max(16px, 1em);
    font-family: inherit;
    padding: 0.25em 0.5em;
    background-color: #fff;
    border: 2px solid var(--input-border);
    border-radius: 4px;
}

h4 {
    font-size: 1.7vw;
    color: #ffffff;
    text-align: center;
}

h6 {
    font-size: 2.7vw;
    color: #ffffff;
    text-align: center;
}

h5 {
    font-size: 1vw;
    color: #ffffff;
    text-align: center;
}

a {
    color: #666;
}

a:hover {
    text-decoration: underline;
}

.map {
    width: 80%;
    height: 80%;
    margin: auto;

}

.mapmobile {
    width: 90%;
    height: 300px;
    margin: auto;
}

.is-danger {
    color: hsl(8, 57%, 43%);
}
</style>