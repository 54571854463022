
import 'buefy/dist/buefy.css'

import awsconfig from "./aws-exports";

import { Amplify } from "aws-amplify";

import '@mdi/font/css/materialdesignicons.css' // <-- Just here
Amplify.configure(awsconfig);

import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

applyPolyfills().then(() => {
  defineCustomElements(window);
});
Vue.config.ignoredElements = [/amplify-\w*/];

import Vue from 'vue'
import App from './App.vue'
import * as VueGoogleMaps from "vue2-google-maps" // Import package


import Buefy from 'buefy'


Vue.use(Buefy)

Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyALcsxc8I91QJ3a9zhBfgY6napWaCs7bpo",
    libraries: ['places', 'geometry', 'geocoder']
  }
});
new Vue({
  render: h => h(App),
}).$mount('#app')

