<!--
########################
# My Swiss Lunch V2    #
#  2023                #
#  PHG - Alpinit       #
########################
-->
<template>

  <div>
     <div v-if="authState !== 'signedin'"><br/><br/> 
    Vous n'êtes pas encore partenaire de My Swiss Lunch ? <br/>
    <b-button @click="$emit('infoResto')">Informations, tarifs et inscription </b-button></div>

    <amplify-authenticator >
      
      <div v-if="authState === 'signedin' && user && admin">
        <div>
           <h5> Bienvenue dans l'administration de votre restaurant</h5> <br /><h2><b>{{ admin.nom }}</b> </h2><br /><br />
        </div> 
        <div class="columns">
          <!-- on affiche 7 fois le "configPDJ" en lui transmettant le restoID de l'admin (user connecté) et la date au format texte des 7 prochains jours-->
        <div class="column">
            <ConfigPDJ :restoid="admin.id" :localdate="dateText[0]"> </ConfigPDJ>
          </div>
          <div class="column">
            <ConfigPDJ :restoid="admin.id" :localdate="dateText[1]"> </ConfigPDJ>
          </div>
          <div class="column">
            <ConfigPDJ :restoid="admin.id" :localdate="dateText[2]"> </ConfigPDJ>
          </div>
          <div class="column">
            <ConfigPDJ :restoid="admin.id" :localdate="dateText[3]"> </ConfigPDJ>
          </div></div> <div class="columns">
          <div class="column">
            <ConfigPDJ :restoid="admin.id" :localdate="dateText[4]"> </ConfigPDJ>
          </div>
          <div class="column">
            <ConfigPDJ :restoid="admin.id" :localdate="dateText[5]"> </ConfigPDJ>
          </div>
          <div class="column">
            <ConfigPDJ :restoid="admin.id" :localdate="dateText[6]"> </ConfigPDJ>
          </div>  
          <div class="column">
           
          </div>  

        </div><br /><br />
      </div>
      <div class="columns">
        <div class="column is-four-fifth"></div>
        <div class="column is-one-fifth">

          <amplify-sign-out> </amplify-sign-out>
        </div>
      </div>
    </amplify-authenticator>

  </div>
</template>

<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import * as queries from '../graphql/queries';
import { API } from 'aws-amplify';
import { Resto } from '../models';

import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";

import ConfigPDJ from "./ConfigPDJ.vue"
export default {
  name: 'EspaceResto',

  components: {
    ConfigPDJ
  },

 async  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
  
      this.authState = authState;
      this.user = authData;
      
    });


    I18n.putVocabulariesForLanguage("fr", {
    [Translations.BACK_TO_SIGN_IN]: "Retour à la connexion",
    [Translations.CHANGE_PASSWORD_ACTION]: "Changement",
    [Translations.CHANGE_PASSWORD]: "Changer le mot de passe",
    [Translations.CODE_LABEL]: "Code de vérification",
    [Translations.CODE_PLACEHOLDER]: "Entrez le code",
    [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: "Code de confirmation",
    [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: "Entrez votre code",
    [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: "Confirmer l'inscription",
    [Translations.CONFIRM_SIGN_UP_LOST_CODE]: "Vous avez perdu votre code?",
    [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: "Renvoyer le code",
    [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: "Confirmer",
    [Translations.CONFIRM_SMS_CODE]: "Confirmer le code SMS",
    [Translations.CONFIRM_TOTP_CODE]: "Confirmer le code TOTP",
    [Translations.CONFIRM]: "Confirmer",
    [Translations.CREATE_ACCOUNT_TEXT]: " ",
    [Translations.EMAIL_LABEL]: "Adresse e-mail *",
    [Translations.EMAIL_PLACEHOLDER]: "amplify@example.com",
    [Translations.FORGOT_PASSWORD_TEXT]: "Mot de passe oublié?",
    [Translations.LESS_THAN_TWO_MFA_VALUES_MESSAGE]: "Moins de deux types de mfa disponibles",
    [Translations.NEW_PASSWORD_LABEL]: "Nouveau mot de passe",
    [Translations.NEW_PASSWORD_PLACEHOLDER]: "Entrez votre nouveau mot de passe",
    [Translations.NO_ACCOUNT_TEXT]: " ",
    [Translations.PASSWORD_LABEL]: "Mot de passe *",
    [Translations.PASSWORD_PLACEHOLDER]: "Tapez votre mot de passe",
    [Translations.PHONE_LABEL]: "Numéro de téléphone *",
    [Translations.PHONE_PLACEHOLDER]: "(555) 555-1212",
    [Translations.QR_CODE_ALT]: "qrcode",
    [Translations.RESET_PASSWORD_TEXT]: "Réinitialiser le mot de passe",
    [Translations.RESET_YOUR_PASSWORD]: "Réinitialisez votre mot de passe",
    [Translations.SELECT_MFA_TYPE_HEADER_TEXT]: "Sélectionnez le type MFA",
    [Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT]: "Vérifier",
    [Translations.SEND_CODE]: "Envoyer le code",
    [Translations.SETUP_TOTP_REQUIRED]: "TOTP doit être configuré",
    [Translations.SIGN_IN_ACTION]: "Se connecter",
    [Translations.SIGN_IN_HEADER_TEXT]: "Connectez-vous à votre espace restaurateur",
    [Translations.SIGN_IN_TEXT]: "se connecter",
    [Translations.SIGN_IN_WITH_AMAZON]: "Connectez-vous avec Amazon",
    [Translations.SIGN_IN_WITH_AUTH0]: "Connectez-vous avec Auth0",
    [Translations.SIGN_IN_WITH_AWS]: "Connectez-vous avec AWS",
    [Translations.SIGN_IN_WITH_FACEBOOK]: "Connectez-vous avec Facebook",
    [Translations.SIGN_IN_WITH_GOOGLE]: "Connectez-vous avec Google",
    [Translations.SIGN_OUT]: "Déconnexion",
    [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: "Email",
    [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: " ",
    [Translations.SIGN_UP_HEADER_TEXT]: " ",
    [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: "Mot de passe",
    [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: " ",
    [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: "Adresse email",
    [Translations.SUCCESS_MFA_TYPE]: "Succès! Votre type MFA est maintenant:",
    [Translations.TOTP_HEADER_TEXT]: "Scannez puis entrez le code de vérification",
    [Translations.TOTP_LABEL]: "Entrer le code de sécurité:",
    [Translations.TOTP_SETUP_FAILURE]: "La configuration TOTP a échoué",
    [Translations.TOTP_SUBMIT_BUTTON_TEXT]: "Vérifier le jeton de sécurité",
    [Translations.TOTP_SUCCESS_MESSAGE]: "Configurez TOTP avec succès!",
    [Translations.UNABLE_TO_SETUP_MFA_AT_THIS_TIME]: "Échoué! Impossible de configurer MFA pour le moment",
    [Translations.USERNAME_LABEL]: "Adresse email *",
    [Translations.USERNAME_PLACEHOLDER]: "Entrez votre adresse email",
    [Translations.VERIFY_CONTACT_EMAIL_LABEL]: "Email",
    [Translations.VERIFY_CONTACT_HEADER_TEXT]: "La récupération de compte nécessite des informations de contact vérifiées",
    [Translations.VERIFY_CONTACT_PHONE_LABEL]: "Numéro de téléphone",
    [Translations.VERIFY_CONTACT_SUBMIT_LABEL]: "Soumettre",
    [Translations.VERIFY_CONTACT_VERIFY_LABEL]: "Vérifier"
    });

    I18n.setLanguage('fr');

  },
  data() {
    return {
      user: undefined, //contient les données de l'user "authenticator"
      authState: undefined, //statut de la connexion
      unsubscribeAuth: undefined, //statut de deconnexion
      admin: Resto, //l'admin : c'est le restaurant de l'administrateur connecté
      currentDate: '', //la date du jour
      dateText: [], //la date en format texte pour les 7 prochains jours sous forme de tableau
    };
  }, watch: {
    //si user est modifié, on recup dans 'admin' le resto sur la base de l'email de user
    async user() {
      this.admin = await this.getRestoFromMail(this.user.attributes.email);
    },
   
    
  },
  methods: {
    //renvoie le resto courant sur la base de l'email de l'admin
    async getRestoFromMail(emaila) {
    
      let filter = {
                
        email: {
                    eq: emaila
                }
            };
     const modelsRestoTemp = await API.graphql({ query: queries.listRestos, variables: {  filter : filter} }); //on récupère les resto pertinents
     const modelResto = modelsRestoTemp.data.listRestos.items[0];
      return modelResto;


      
    },
  },
  async mounted() {

    this.currentDate = new Date().toISOString().slice(0, 10); // on configure la date du jour au format texte 10 caractères
    var tempDate = new Array;

    //on crée un tableau qui contient toutes les dates des 7 prochains jours
    tempDate.push(new Date());
    tempDate.push(new Date());
    tempDate.push(new Date());
    tempDate.push(new Date());
    tempDate.push(new Date());
    tempDate.push(new Date());
    tempDate.push(new Date());
    tempDate[1].setDate(tempDate[1].getDate() + 1);
    tempDate[2].setDate(tempDate[2].getDate() + 2);
    tempDate[3].setDate(tempDate[3].getDate() + 3);
    tempDate[4].setDate(tempDate[4].getDate() + 4);
    tempDate[5].setDate(tempDate[5].getDate() + 5);
    tempDate[6].setDate(tempDate[6].getDate() + 6);
    //on met à jour le tableau dateText en y poussant les dates des 7 prochains jours, au format string 10 caractères
    this.dateText.push(tempDate[0].toISOString().slice(0, 10))
    this.dateText.push(tempDate[1].toISOString().slice(0, 10))
    this.dateText.push(tempDate[2].toISOString().slice(0, 10))
    this.dateText.push(tempDate[3].toISOString().slice(0, 10))
    this.dateText.push(tempDate[4].toISOString().slice(0, 10))
    this.dateText.push(tempDate[5].toISOString().slice(0, 10))
    this.dateText.push(tempDate[6].toISOString().slice(0, 10))
    this.admin = await this.getRestoFromMail(this.user.attributes.email);
  }
}
</script>
  <style>
  :root {
  --amplify-primary-color:hsl(8, 57%, 43%);
  --amplify-primary-tint:hsl(8, 57%, 43%);
  --amplify-primary-shade: hsl(8, 57%, 43%);
}


.linkred {
  font-size: 18px;
  color:hsl(8, 57%, 43%);
}</style>
