/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPdj = /* GraphQL */ `
  mutation CreatePdj(
    $input: CreatePdjInput!
    $condition: ModelPdjConditionInput
  ) {
    createPdj(input: $input, condition: $condition) {
      id
      restoID
      nompdj
      prixpdj
      dispo
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePdj = /* GraphQL */ `
  mutation UpdatePdj(
    $input: UpdatePdjInput!
    $condition: ModelPdjConditionInput
  ) {
    updatePdj(input: $input, condition: $condition) {
      id
      restoID
      nompdj
      prixpdj
      dispo
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePdj = /* GraphQL */ `
  mutation DeletePdj(
    $input: DeletePdjInput!
    $condition: ModelPdjConditionInput
  ) {
    deletePdj(input: $input, condition: $condition) {
      id
      restoID
      nompdj
      prixpdj
      dispo
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createResto = /* GraphQL */ `
  mutation CreateResto(
    $input: CreateRestoInput!
    $condition: ModelRestoConditionInput
  ) {
    createResto(input: $input, condition: $condition) {
      id
      nom
      adresse
      lat
      lng
      site
      tel
      email
      Pdjs {
        nextToken
        startedAt
      }
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateResto = /* GraphQL */ `
  mutation UpdateResto(
    $input: UpdateRestoInput!
    $condition: ModelRestoConditionInput
  ) {
    updateResto(input: $input, condition: $condition) {
      id
      nom
      adresse
      lat
      lng
      site
      tel
      email
      Pdjs {
        nextToken
        startedAt
      }
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteResto = /* GraphQL */ `
  mutation DeleteResto(
    $input: DeleteRestoInput!
    $condition: ModelRestoConditionInput
  ) {
    deleteResto(input: $input, condition: $condition) {
      id
      nom
      adresse
      lat
      lng
      site
      tel
      email
      Pdjs {
        nextToken
        startedAt
      }
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
