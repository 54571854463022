<template>
  <div class="about">
            <div>
           
                <h5>My Swiss Lunch - Comment ça marche ?</h5>
            
            </div>
    <section>    <div class="container">


      <br/><br/>

      <b-collapse
        class="card"
        animation="slide"
        aria-id="contentIdForA11y3"
        :open="false"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
          >
            <p class="card-header-title"> My Swiss Lunch, c'est quoi ?</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-up' : 'menu-down'"> </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            My Swiss Lunch est une plateforme gratuite permettant de trouver à proximité de votre position ou d’une
adresse vos plats du jour jusqu’à une semaine à l’avance, de consulter directement le site du restaurateur ou de téléphoner pour réserver.<br/>
En quelque clics vous êtes sûr de trouver votre plat du jour et sûrement de découvrir de nouvelles adresses.<br/>
          </div>
        </div>
      </b-collapse>

      <b-collapse
        class="card"
        animation="slide"
        aria-id="contentIdForA11y3"
        :open="false"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
          >
            <p class="card-header-title">Comment utiliser My Swiss Lunch ?</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-up' : 'menu-down'"> </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            Pour utiliser My Swiss Lunch, rien de plus simple ! 
            <br />
            1. Ouvrez le site sur votre smartphone, votre tablette ou votre ordinateur <br />
            2. Créez un raccourci ou un favori pour nous retrouver plus vite demain midi ! <br />
            3. Entrez votre adresse, ou choisissez la géolocalisation depuis votre mobile (pensez à accepter l'accès à votre localisation lorsque cela vous sera demandé !)<br />
            4. Entrez la date pour laquelle vous cherchez un plat du jour
            <br />
            5. Cliquez sur "Rechercher" <br />
            6. Consultez les résultats <br />
            7. Régalez-vous !
          </div>
        </div>
      </b-collapse>

      

      <b-collapse
        class="card"
        animation="slide"
        aria-id="contentIdForA11y3"
        :open="false"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
          >
            <p class="card-header-title">
              Qui Sommes Nous ?
            </p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-up' : 'menu-down'"> </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            L’idée de My Swiss Lunch est née au cours d’un déjeuner entre amis. L’un des sujet abordé
était la difficulté de connaître les plats du jour proposé par les restaurants autour de nous.
D’autres points intéressants sont ressortis de cette discussion.<br/>
La contrariété d’aller dans un restaurant dont le plat du jour nous plaisait et de constater une
fois installé, qu’il n’y en avait plus.<br/>
Découvrir après des années que le petit restaurant du coin de la rue proposait d’excellents plats
du jour mais que jamais nous n’étions passé devant.<br/>
<br/><br/>
Une chose en amenant une autre, le concept de My Swiss Lunch était donc né.<br/>
Un site simple permettant de choisir un plat du jour parmi les restaurants à proximité et ce jusqu’à une semaine à l’avance.<br/>

Après plusieurs mois d’échanges, de discussions et beaucoup de rires, le site a enfin vu le jour.<br/>
Tous les points qui nous tenaient à cœur ont été respectés :<br/>
Un site simple d’utilisation.<br/>
Un outil efficace pour les utilisateurs comme pour les restaurateurs.<br/>
La possibilité de découvrir de nouveaux lieux.<br/><br/>
Bon appétit.<br/>
Votre team My Swiss Lunch<br/>
          </div>
        </div>
      </b-collapse>

      <b-collapse
        class="card"
        animation="slide"
        aria-id="contentIdForA11y3"
        :open="false"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
          >
            <p class="card-header-title">Questions / Suggestions</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-up' : 'menu-down'"> </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            Vous n’avez pas trouvé de réponse dans nos pages?
Vous avez des suggestions à nous transmettre ?
N’hésitez pas à nous contacter en utilisant la rubrique "Nous écrire" en bas de cette page.
          </div>
        </div>
      </b-collapse></div>
    </section><br/><br/>

  </div>
</template>

<script>
export default {
  name:"AboutUs"
}
</script>

<style scoped>
.card-header-title:hover {
  background-color:lightgray;
}
.content {
  text-align:left;
}
</style>
