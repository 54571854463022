<!--
########################
# My Swiss Lunch V2    #
#  2023                #
#  PHG - Alpinit       #
########################
-->

<template>
  <div id="app">
    <!-- menu ligne header-->
    <section>
      <div class="columns">
        <div class="column">
          <img src="./assets/logov3.png" @click="backtoHome" alt="Nature" class="logodesktop">
        </div>

      <!-- Si on est en mode resto, on n'affiche pas la colonne rouge dans le header -->
      <div v-if="currentPage == 2 || currentPage == 3 || currentPage == 4"
          class="column is-one-third is-hidden-mobile "></div>
        <div v-else class="column is-one-third is-hidden-mobile has-background-primary"></div>

        <div class="column is-half is-hidden-mobile">

          <!--  <a style="position: absolute; right:0px;" class="linkadminResto" @click="updateRestoMode"> {{
                label_bt_espaceResto
            }}</a>-->
        </div>
      </div>
    </section>
    <div>
      <!-- contenu texte dynamique-->
      <section v-if="currentPage == 0">
        <div class="columns">
          <div class="column">

          </div>

          <div class="column is-one-third has-background-primary">
            <h1> Trouver </h1>
            <h3> mon plat du jour </h3>
          </div>

          <div class="column is-half ">
            <h2> {{ text }}</h2>

          </div>
        </div>


      </section>

      <!-- contenu central : images + barre de recherche -->

      <section v-if="currentPage == 0">
        <!-- Si on est sur la page d'accueil-->
        <div class="columns">
          <div class="column is-hidden-mobile">
            <img src="./assets/perso.svg" style="width:150%;" class="responsive">
          </div>

          <div class="column is-one-third has-background-primary">
            <div class="container is-fluid">
              <!-- partie centrale : autocomplete et 2 boutons -->
              <gmap-autocomplete :country="['fr', 'ch']" :placeholder="PHAutocomplete" class="input"
                @place_changed="initMarker">
              </gmap-autocomplete>
              <br /><br />


              <b-dropdown v-model="dateRef" aria-role="list">
                <template #trigger>
                  <b-button :label=dateRef type="is-primary" icon-right="menu-down" />
                </template>

                <b-dropdown-item v-for="(indexref) in dateArray" :key="indexref" :value="indexref" aria-role="listitem">
                  <div class="media">


                    {{ indexref }}

                  </div>
                </b-dropdown-item>


              </b-dropdown>

              <br />


              &nbsp;&nbsp;&nbsp;
              <b-button v-if="onGoingGeoLocate" loading>Me localiser</b-button>
              <!-- utilisation d'un booléen pour mettre la geoloc en "waiting"-->
              <b-button v-else @click="locateGeoLocation">Me Localiser</b-button> &nbsp;&nbsp;&nbsp;
              <b-button @click="startsearch(1)"> Rechercher </b-button>
            </div>
          </div>

          <div class="column is-half">
            <img src="./assets/poulet.svg" class="responsive">
            <div class="is-hidden-mobile">
            </div>

            <section>
              <div class="has-text-right">
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>


    <!-- mode Resultats -->
    <div>
      <section v-if="currentPage == 1">
        <SearchResult :positionCourante="ValueAutocomplete" :center="center" :platsdujour="platsdujour"
          :currentDateTxt="currentDateTxt" :PasdePDJTxt="PasdePDJTxt" @centerupdated="updateCenter" />
        <!-- on transmet la position sous forme de texte (adresse) pour affichage, le centre en coordonnées, les pdj. si le centre est mis à jour on récupère l'info-->
      </section>
    </div>

    <!--- MODE ADMIN RESTAURANT -->
    <section v-if="currentPage == 2">
      <EspaceResto @infoResto="openInfoResto" /> <!-- on affiche le mode administration du restaurant -->
    </section>

    <!--- MODE ADMIN RESTAURANT -->
    <section v-if="currentPage == 3">
      <AboutUs /> <!-- on affiche le mode Qui Sommes Nous ? -->
    </section>

    <!--- MODE ADMIN RESTAURANT -->
    <section v-if="currentPage == 4">
      <AboutPro /> <!-- on affiche le mode info inscription resto -->
    </section>

    <!--- FOOTER -->
    <footer class="footer">
      <div class="is-hidden-desktop is-hidden-tablet">
        Concocté avec <img style="float:center;max-height:30px" src="./assets/assiette_coeur_mini.png"> en Suisse
        <br />par l'équipe My Swiss Lunch © 2023
    </div>
      <div class="is-hidden-mobile ">
        Concocté avec <img style="float:center;max-height:30px" src="./assets/assiette_coeur_mini.png"> en Suisse par
        l'équipe My Swiss Lunch © 2023
      </div> <br /> <b-button @click="updateRestoMode">{{
        label_bt_espaceResto
      }}</b-button> <br /><a class="linkadmin" @click="openAbout"> Qui sommes nous ? </a> -
      <a class="linkadmin" href="CGV.html" target="_blank"> Conditions Générales </a> - <!--<a class="linkadmin" @click="openPrivacy">
          Cookies et vie privée </a> - --> <a class="linkadmin" href="mailto:contact@myswisslunch.com"> Nous écrire </a>
      <br /> <a class="linkadmin" @click="openInfoResto"> Informations et inscriptions Restaurateur </a>

    </footer>

    <!--- Modaux liés aux footer -->






  </div>
</template>

<script>

import PlatDuJour from "./PlatDuJour.js"; //model de pdj adapté pour l'affichage avec les infos restos inclus
import * as queries from './graphql/queries';
import { API } from 'aws-amplify';

import SearchResult from "./components/SearchResult";
import EspaceResto from "./components/EspaceResto"
import AboutUs from "./components/AboutUs";
import AboutPro from "./components/AboutPro";

export default {
  name: 'App',
  components: {
    SearchResult, EspaceResto, AboutUs, AboutPro
  },

  data() {
    return {
      isPrivacyModalActive: false, //ouverture du modal Privacy
      onGoingGeoLocate: false, //mise en attente du boutton "geoloc"
      PHAutocomplete: "Pont du Mont Blanc - Geneve", // place holder pour l'autocomplete
      ValueAutocomplete: 'Pont du Mont Blanc - Geneve', //valeur par défaut de l'adresse de recherche
      currentPage: 0,//0 : homepage, 1 : resultat, 2 : espace resto 
      list: ['à proximité', 'à toute heure', 'autour de moi', 'quand j\'en ai envie'], //liste tournante de texte pour la page d'accueil
      index: 0, //index tournant pour le texte qui tourne
      text: '', //text affiché qui tourne
      speed: 2000, // vitesse de rotation
      label_bt_espaceResto: "Espace Restaurateur", //nom du bouton pour aller sur l'espace resto, qui se met à jour pour revenir en page d'accueil
      platsdujour: [], // plats du jour contenant toutes les infos, transmise aux restultat
      currentDateTxt: "aujourd'hui",// date du jour en texte affiché en milieu de phrase ! (attention à la syntaxe)

      dateRef: "aujourd'hui",
      center: { //le centre utilisé pour les recherches. ici le pont du mont blanc
        lat: 46.2043907,
        lng: 6.1431577
      },

    };
  },
  computed:
  {
    PasdePDJTxt() {
      if (this.platsdujour.length == 0) {
        return "Désolé ! Aucun plat du jour n'est actuellement disponible dans cette zone !"
      } else {
        return "";
      }
    },
    dateArray() {

      //Date du jour
      // var currentdate = new Date().toISOString().slice(0, 10);
      var auj=new Date();
      var apresdemain = new Date();
      console.log("aujourd'hui", apresdemain);
      var jour4 = new Date();
      var jour5 = new Date();
      var jour6 = new Date();
      var jour7 = new Date();
      apresdemain.setDate(auj.getDate() + 2);
      jour4.setDate(auj.getDate() + 3);
      jour5.setDate(auj.getDate() + 4);
      jour6.setDate(auj.getDate() + 5);
      jour7.setDate(auj.getDate() + 6 );
      const options = { year: 'numeric', month: 'long', day: 'numeric' };

      return ["aujourd'hui", 'demain', 'le ' + apresdemain.toLocaleDateString('fr-FR', options), 'le ' + jour4.toLocaleDateString('fr-FR', options), 'le ' + jour5.toLocaleDateString('fr-FR', options), 'le ' + jour6.toLocaleDateString('fr-FR', options), 'le ' + jour7.toLocaleDateString('fr-FR', options)]
    }
  },
  watch:
  {
    currentPage() { //si "currentPage" change, on modifie le texte du lien
      if (this.currentPage == 2) {
        this.label_bt_espaceResto = "Revenir à My Swiss Lunch";
      } else {
        this.label_bt_espaceResto = "Espace Restaurateur";
      }

    }
  },
  methods:
  {
    openInfoResto() {
      this.currentPage = 4;
    },
    openAbout() { // ouvre About
      this.currentPage = 3;
    },
    openPrivacy() { // ouvre le privacy modal
      this.isPrivacyModalActive = true;
    },
    async startsearch(idpage) { //affiche les resultats de recherche

      //mise a zero de la liste des PDJ
      this.platsdujour = [];
      let indexdate = this.dateArray.indexOf(this.dateRef);
      this.currentDateTxt = this.dateRef;


      //Date du jour
      var datedujour = new Date();
      var dateSearch = new Date();
      dateSearch.setDate(datedujour.getDate() + indexdate);


      var currentdate = dateSearch.toISOString().slice(0, 10);


      //recuperer les plats du jour de la journée qui sont disponibles
      let filter = {
        date: {
          eq: currentdate
        },
        dispo: {
          eq: true
        }
      };
      const PDJFiltered = await API.graphql({ query: queries.listPdjs, variables: { filter: filter, limit:2000 } }); //on récupère les PDJ pertinents
      PDJFiltered.data.listPdjs.items.forEach(obj => this.updatePDJ(obj));


     console.log("pdj",PDJFiltered);


      this.currentPage = idpage;

    },
    backtoHome() { //reviens a la page d'accueil
      this.currentPage = 0;
    },
    erreurGeoloc(err) {//affiche en console l'erreur de geolocalisation
      console.warn(`ERREUR (${err.code}): ${err.message}`);
    },
    initMarker(loc) { //lorsque l'autocomplete est validé (adresse confirmée) on met à jour le centre et la valeur du PDJ
      const marker = {
        lat: loc.geometry.location.lat(),
        lng: loc.geometry.location.lng()
      };
      this.center = marker;
      this.ValueAutocomplete = loc.formatted_address;
      this.updatePDJDistance();
    },

    locateGeoLocation: function () { //lorsque l'on clic sur "geolocaliser"
      this.onGoingGeoLocate = true; //on met le bouton en loading

      navigator.geolocation.getCurrentPosition(res => {
        // en cas de success on met à jour le centre
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude
        };
        this.updatePDJDistance(); // on update les distances
        this.updateAutocomplete(); //on inscript l'adresse dans l'autocomplete
      }, this.erreurGeoloc); // en cas d'erreur de geoloc on affiche le message dans la console
    },

    updateAutocomplete() { //en cas de réussite de la geoloc on demande a google l'adresse correspondante
      var a = new window.google.maps.Geocoder();
      a.geocode({ location: this.center })
        .then((response) => {
          if (response.results[0]) {
            this.ValueAutocomplete = response.results[0].formatted_address; //on met à jour la valeur avec l'adresse
            this.onGoingGeoLocate = false; // on débloque le bouton de chargement
            this.currentPage = 1; //on va aux résultats
          } else {

            this.onGoingGeoLocate = false;
          }
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    },

    updateRestoMode() { //passer du mode resto au mode recherche
      if (this.currentPage == 2)
        this.currentPage = 0;
      else
        this.currentPage = 2;
    },
    updateCenter(value) { //mise à jour du centre + des distances
      this.center = value;
      this.updatePDJDistance();
    },
    async getResto(idt) {//recupérer un resto par son id
       let filter = {
                
                id: {
                    eq: idt
                }
            };
     const modelsRestoTemp = await API.graphql({ query: queries.listRestos, variables: {  filter : filter} }); //on récupère les resto pertinents
     const modelResto = modelsRestoTemp.data.listRestos.items[0];

      return modelResto;
    },
    async updatePDJ(item) { // récupérer le PDJ d'un restaurant donné
      var restocourant;
      restocourant = await this.getResto(item.restoID); // on recuepre le resto sur la base de son ID
      var distancecalc = 325;
      var distancetxt = "325 m"
      var lat = this.center.lat;
      var lng = this.center.lng
      var latresto = restocourant.lat;
      var lngresto = restocourant.lng;

      if (restocourant) { // si on a bien un resto on calcule la distance resto - point de recherche (le centre)
        var a = new window.google.maps.LatLng(latresto, lngresto);
        var b = new window.google.maps.LatLng(lat, lng);
        var c = await this.fetchDistanceAPI(a, b);

        distancecalc = parseInt(c.rows[0].elements[0].distance.value);
        distancetxt = c.rows[0].elements[0].distance.text;


      }


      // on crée un PlatDuJour avec le modèle local, qui contient les infos du resto et les infos du pdj
      var platduj22 = new PlatDuJour(
        item.id,
        restocourant.nom,
        parseFloat(restocourant.lat),
        parseFloat(restocourant.lng),
        distancecalc,
        restocourant.adresse,
        item.nompdj,
        item.prixpdj,
        restocourant.site,
        restocourant.tel,
        restocourant.type,
        distancetxt,
      );
      this.platsdujour.push(platduj22); //on l'ajout au tableau des platsdujour
    },

    async fetchDistanceAPI(origin, dest) {
      return new Promise((resolve, reject) => {
        let response;
        var service = new window.google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [dest],
            travelMode: "WALKING",
            unitSystem: window.google.maps.UnitSystem.metric,
          },
          function (resp, status) {
            if (status !== window.google.maps.DistanceMatrixStatus.OK) {
              response = reject(status);
            } else {
              response = resolve(resp);
            }
          }
        );
        return response;
      });
    },

    async updatePDJDistance() { //met à jour la distance de tous les PDJ

      this.platsdujour.forEach(async item => {

        var distancecalc = 325;
        var distancetxt = "325 m"
        var lat = this.center.lat;
        var lng = this.center.lng
        var latresto = item.position.lat;
        var lngresto = item.position.lng;

        var a = new window.google.maps.LatLng(latresto, lngresto);
        var b = new window.google.maps.LatLng(lat, lng);
        var c = await this.fetchDistanceAPI(a, b);

        distancecalc = parseInt(c.rows[0].elements[0].distance.value);
        distancetxt = c.rows[0].elements[0].distance.text;

        item.distance = distancecalc;
        item.distancetxt = distancetxt;
      });


    },
    getTextFromList() { // pour la mise à jour des données de l'affichage qui tourne
      var vm = this;

      vm.text = vm.list[vm.index];
      vm.index += 1;

      if (vm.index >= vm.list.length) {
        vm.index = 0;
      }
    },



  },
  async created() {


    //await this.getPDJListing();
  },

  async mounted() {
//COOKIEBOT SCRIPT #1/2
   // let externalScript = document.createElement('script')
   // externalScript.setAttribute('src', 'https://consent.cookiebot.com/e6fd56fd-faac-4784-a6d2-577ac480dd00/cd.js')
   // document.head.appendChild(externalScript)


    //gestion de l'affichage qui tourne
    this.text = this.list[this.index];
    setInterval(this.getTextFromList, this.speed);
this.startsearch(0);


  }
}


</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

h1 {
  font-size: 8vw;
  color: #ffffff;
  text-align: center;

}

h2 {
  font-size: 5vw;
  color: hsl(8, 57%, 43%);
  text-align: center;
}

h3 {
  font-size: 3vw;
  color: #ffffff;
  text-align: center;
}

h4 {
  font-size: 1vw;
  color: #ffffff;
  text-align: center;
}

h5 {
  font-size: 2vw;
  color: #000000;
  text-align: center;
}

.has-background-primary {
  background-color: hsl(8, 57%, 43%) !important;
}

.responsive {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;


}

.logodesktop {

  max-width: 120px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;

}

.linkadmin {
  font-size: 10px;
  color: #000000;
}

.linkadminResto {
  font-size: 15px;
  color: #000000;
}
</style>


<style lang="scss">
@import "@/design/index.scss";
</style>

