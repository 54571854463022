export default class PlatDuJour {
    id = "";
    restonom = "";
    distance = 0 ;
    distancetxt = "0";
    restoadresse = "";
    pdjnom = "";
    pdjprix = 0 ;
    position = {lat : 0, lng : 0} ;
    site = "";
    tel = "";
    type = "";
  
 
    constructor(pdjid, rname, lati, long, dist, rad, pname, pprice, rsite, rtel, rtype, distancetxt) 
    {
        this.id = pdjid;
      this.restonom = rname;
      this.distance = dist;
      this.restoadresse = rad;
      this.pdjnom = pname;
      this.pdjprix = pprice;
      this.position = {lat : lati, lng : long} ;
      this.site = rsite ;
      this.tel = rtel ;
      this.type = rtype ;
      this.distancetxt = distancetxt ;
    }
    
    
}