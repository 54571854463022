// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Pdj, Resto } = initSchema(schema);

export {
  Pdj,
  Resto
};