<template>
  <div class="about">
            <div>
           
                <h5>My Swiss Lunch <br> Informations, tarifs et inscription </h5>
            
            </div>
    <section>    <div class="container">


    <br/><br/>

      <b-collapse
        class="card"
        animation="slide"
        aria-id="contentIdForA11y3"
        :open="false"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
          >
            <p class="card-header-title"> Tarifs</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-up' : 'menu-down'"> </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
Nos tarifs sont fixes et forfaitaires à 85.- chfs ht quelque soit le nombre de visiteurs sur votre compte.<br/>
Les factures sont établies mensuellement<br/>
Il n’y a pas d’abonnement pour l’instant.<br/>
Des options vous seront proposées qui, en cas d’acceptation de votre part, seront ajoutées directement sur votre facture.<br/>

          </div>
        </div>
      </b-collapse>

      <b-collapse
        class="card"
        animation="slide"
        aria-id="contentIdForA11y3"
        :open="false"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
          >
            <p class="card-header-title">Inscription</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-up' : 'menu-down'"> </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            Vous pouvez vous inscrire en remplissant le formulaire accessible par
    <a href="https://forms.gle/tx5T9QFzK2hFECdJ9" class="linkred" target="blank">ce lien</a>, qui nous permettra de créer votre compte.<br/> Nous vous contacterons ensuite pour valider avec vous votre inscription.

Vous recevrez alors par email :<br/>
Le mode d’emploi de votre espace restaurateur, nos conditions générales, le récapitulatif de vos informations et la facture du premier mois d’utilisation.<br/>
Lors de la création de votre compte, vous recevez votre code d’accès personnel à changer lors de la première connexion.

          </div>
        </div>
      </b-collapse>

      <b-collapse
        class="card"
        animation="slide"
        aria-id="contentIdForA11y3"
        :open="false"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
          >
            <p class="card-header-title">Comment utiliser le service ?</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-up' : 'menu-down'"> </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            L'utilisation est très simple ! <br/>
            Lors de votre inscription, vous recevrez un guide d'utilisation détaillé lors de votre inscription, et vous pouvez nous contacter pour toute question !
          </div>
        </div>
      </b-collapse>

      <b-collapse
        class="card"
        animation="slide"
        aria-id="contentIdForA11y3"
        :open="false"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
          >
            <p class="card-header-title">
              Résiliation
            </p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-up' : 'menu-down'"> </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            La résiliation de votre compte se fait par un simple mail en cliquant sur "Nous écrire" en bas de cette page, sans justification avant le 15 du mois en cours. <br/>
            La résiliation sera effective à la fin du mois en cours qui sera facturé.
          </div>
        </div>
      </b-collapse>

      <b-collapse
        class="card"
        animation="slide"
        aria-id="contentIdForA11y3"
        :open="false"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
          >
            <p class="card-header-title">Les avantages de My Swiss Lunch</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-up' : 'menu-down'"> </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
-	My Swiss Lunch nécessite peu d’intervention sur le site de votre part<br/>
-	Vous donnez des informations à vos clients actuels<br/>
-	L'inscription et la résiliation sont simples <br/>
-	Le tarif est fixe, bas, et sans mauvaise surprise<br/>
-	Et enfin, qui n’a jamais cherché à savoir quels plats du jour étaient disponibles à proximité ?<br/>

          </div>
        </div>
      </b-collapse></div><br/><br/>
<center><a href="https://forms.gle/tx5T9QFzK2hFECdJ9" class="linkred" target="blank">
<b-button href="https://forms.gle/tx5T9QFzK2hFECdJ9" target="blank">Je veux m'inscrire</b-button></a></center>
    </section>
    <br/><br/>
</div>
</template>

<script>
export default {
  name:"AboutUs"
}
</script>

<style scoped>
.card-header-title:hover {
  background-color:lightgray;
}

.content{
  text-align: left;
}
</style>
