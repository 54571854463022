/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPdj = /* GraphQL */ `
  query GetPdj($id: ID!) {
    getPdj(id: $id) {
      id
      restoID
      nompdj
      prixpdj
      dispo
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listPdjs = /* GraphQL */ `
  query ListPdjs(
    $filter: ModelPdjFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPdjs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        restoID
        nompdj
        prixpdj
        dispo
        date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPdjs = /* GraphQL */ `
  query SyncPdjs(
    $filter: ModelPdjFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPdjs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        restoID
        nompdj
        prixpdj
        dispo
        date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getResto = /* GraphQL */ `
  query GetResto($id: ID!) {
    getResto(id: $id) {
      id
      nom
      adresse
      lat
      lng
      site
      tel
      email
      Pdjs {
        nextToken
        startedAt
      }
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listRestos = /* GraphQL */ `
  query ListRestos(
    $filter: ModelRestoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRestos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nom
        adresse
        lat
        lng
        site
        tel
        email
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRestos = /* GraphQL */ `
  query SyncRestos(
    $filter: ModelRestoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRestos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        nom
        adresse
        lat
        lng
        site
        tel
        email
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
