<!--
########################
# My Swiss Lunch V2    #
#  2023                #
#  PHG - Alpinit       #
########################
-->
<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
                {{ localdatetxt }}
        </p>


        </header>
        <div class="card-content">
            <div class="content">
                <!--<b-field label="Date">
                <b-input :placeholder=localdate disabled></b-input>
            </b-field>-->
                <b-field label="Nom du plat">
                    <b-input v-model="localpdjnom" maxlength="90"></b-input>
                </b-field>
                <b-field label="Prix (CHF)">
                    <b-input v-model="localpdjprix" type="number" step="0.01" min="0" max="99"></b-input>
                </b-field>

                <br />
                <b-checkbox v-model="localpdjdispo"> Disponible
                </b-checkbox>
                <br /> <br />

            </div>
        </div>

        <footer class="card-footer">
            <a @click="savePDJ" class="card-footer-item">Enregistrer</a>

        </footer>



    </div>
</template>



<script>

//import PlatDuJour from "./PlatDuJour.js";

import { Pdj } from '../models';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { API } from 'aws-amplify';

export default {
    data() {
        return {
            name: 'John Silver',
            platdujour: Pdj, //le plat du jour local au format AWS pour pouvoir l'updater ou le créer
            localpdjnom: '', //le nom du PDJ (lié à l'input)
            localpdjprix: '', // son prix (idem)
            localpdjdispo: true, // sa dispo (idem)
            localpdjdate: '', //sa date (pas modifiable mais idem)
            localdatetxt: '',//la date en version textuelle pour affichage

        }
    },
    props: {
        restoid: String, //l'identifiant du resto courant
        localdate: String //la date de l'élément courant 
    },
    watch:
    {


        async restoid() { //si le restoid est modifié
            this.updatePDJ(); //on met à jour le plat du jour
        },
        platdujour() { //si le plat du jour est modifié, on met à jour les infos "locales" sur la base de celui-ci
            this.localpdjdate = this.platdujour.date;
            this.localpdjdispo = this.platdujour.dispo;
            this.localpdjnom = this.platdujour.nompdj;
            this.localpdjprix = this.platdujour.prixpdj;
        }
    },

    async mounted() {
        this.updatePDJ(); //au démarrage, on met à jour les infos du PDJ
        const d = new Date(this.localdate);
        const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        let text = d.toLocaleDateString('fr-FR', options);
        this.localdatetxt = text;
    },
    methods: {
        async updatePDJ() { //met à jour les infos du PDJ, en allant chercher sur AWS le PDJ de la date courante pour le resto donné

            //recuperer les plats du jour de la journée qui sont disponibles
            let filter = {
                date: {
                    eq: this.localdate
                },
                restoID: {
                    eq: this.restoid
                }
            };
            const modelsPDJTemp = await API.graphql({ query: queries.listPdjs, variables: { filter: filter, limit:2000 } }); //on récupère les PDJ pertinents 
     
            const modelsPDJ = modelsPDJTemp.data.listPdjs.items;



            if (modelsPDJ[0]) { //s'il y en a un, on l'enregistre localement. Il ne peut théoriquement pas y en avoir deux. Sinon on ne garde que le premier
                this.platdujour = modelsPDJ[0];
            }

        },

        async savePDJ() { //sauvegarder le plat du jour 

            if (this.localpdjnom && this.localpdjprix) { //si les champs ne sont pas vides

                if (this.platdujour.nompdj) { //s'il existe déjà un platdujour 
                    try {


                        const updatePDJData = {
                            date: this.platdujour.date,
                            dispo: this.localpdjdispo,
                            id: this.platdujour.id,
                            nompdj: this.localpdjnom,
                            prixpdj: parseFloat(this.localpdjprix),
                            restoID: this.platdujour.restoID,
                            _version: this.platdujour._version
                        };
                        await API.graphql({ query: mutations.updatePdj, variables: { input: updatePDJData } });

                    } catch (error) {
                        console.log("Error saving pdj", error);
                    }

                } else {
                    try {
                        try {

                            const newPDJData = {
                                restoID: this.restoid,
                                nompdj: this.localpdjnom,
                                prixpdj: parseFloat(this.localpdjprix),
                                dispo: this.localpdjdispo,
                                date: this.localdate
                            };

                            await API.graphql({ query: mutations.createPdj, variables: { input: newPDJData } });

                        } catch (error) {
                            console.log("Error saving pdj", error);
                        }

                    } catch (error) {
                        console.log("Error create pdj", error);
                    }
                }
                this.$buefy.toast.open({
                    duration: 3000,
                    message: `Modification enregistrée !`,
                    position: 'is-bottom'
                })
            } else {
                this.$buefy.toast.open({
                    duration: 3000,
                    message: `Impossible d'enregistrer un plat du jour incomplet  !`,
                    position: 'is-bottom', type: 'is-danger'
                })
            }

            this.updatePDJ();
        }

    }


}
</script>


<style lang="scss">
$primary: hsl(8, 57%, 43%);
$checkbox-active-background-color: $primary;

.card-header-title {
    justify-content: center;
}
</style>